<template>
    <div class="p-5 mb-32">
        <Loading
                :active.sync="isDownload"
                :is-full-page="true"
            >
            <h2 class="text-2xl font-semibold">Mohon tunggu file sedang di download...</h2>
        </Loading>
        <div class="bg-gray-800 shadow-sm">
            <div class="p-5 flex items-center justify-between">
                <div class="flex items-center">
                    <div>
                        <calendar @date="onSelect"></calendar>
                    </div>
                    <div class="ml-3">
                            <button @click="onFilter('2 menit')" :class="{'bg-green-500 text-white border-green-500 bg-opacity-100': filter=='2 menit'}" class="h-9 bg-gray-700 border border-gray-600 rounded-l-sm focus:outline-none px-5 text-gray-300 hover:bg-green-500 hover:border-green-500">2 Menit</button>
                            <button @click="onFilter('1 jam')" :class="{'bg-green-500 text-white border-green-500 bg-opacity-100': filter=='1 jam'}"  class="h-9 bg-gray-700 border border-gray-600 border-l-0 rounded-r-sm focus:outline-none px-5 text-gray-300 hover:bg-green-500 hover:border-green-500">1 Jam</button>
                    </div>
                </div>
                <div>
                    <button @click="exportExcel" :class="label == 'Per Tahun'? 'bg-green-300 cursor-not-allowed':'bg-green-500 hover:bg-green-600'" class="h-9 px-5 rounded-sm text-white focus:outline-none" :disabled="label == 'Per Tahun'">DOWNLOAD</button>
                </div>
            </div>
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">Tanggal</th>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">pH</th>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">Temperatur</th>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">COD(mg/L)</th>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">TSS(mg/L)</th>
                        <th class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">NH3N(mg/L)</th>
                        <th v-if="filter == '2 menit'" class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">Debit(m3/menit)</th>
                        <th v-else class="px-5 py-3 bg-blue-500 bg-opacity-80 text-white">Debit(m3/Jam)</th>
                    </tr>
                </thead>
                <tbody v-if="isLoading"  class="divide-y divide-gray-700">
                    <tr v-for="i in 20" :key="i">
                        <td class="px-5 py-4">
                            <div class="flex">
                                <div class="w-20 h-3 bg-gray-300 animate-pulse"></div>
                                <div class="w-12 h-3 bg-green-300 animate-pulse ml-3"></div>
                            </div>
                        </td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-300 animate-pulse"></div></td>
                    </tr>
                </tbody>
                <tbody v-if="reports" class="divide-y divide-gray-700">
                    <tr v-for="(report, i) in reports" :key="i">
                        <td class="px-5 py-3 text-gray-300">{{report.added_at.substring(10,0)}} <span class="ml-3 text-green-500"><i class="icon-watch2"></i>{{report.added_at.substring(10,19)}}</span> </td>
                        <td class="px-5 py-3 text-gray-300">{{report.ph}}
                            <span v-if="i < reports.length -1 && reports[i].ph < reports[i+1].ph "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].ph > reports[i+1].ph "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].ph == reports[i+1].ph "><i class="icon-menu-open"></i></span>
                            
                        </td>
                        <td class="px-5 py-3 text-gray-300">{{report.tmp}}
                            <span v-if="i < reports.length -1 && reports[i].tmp < reports[i+1].tmp "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].tmp > reports[i+1].tmp "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].tmp == reports[i+1].tmp "><i class="icon-menu-open"></i></span>
                        </td>
                        <td class="px-5 py-3 text-gray-300">{{report.cod}}
                            <span v-if="i < reports.length -1 && reports[i].cod < reports[i+1].cod "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].cod > reports[i+1].cod "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].cod == reports[i+1].cod "><i class="icon-menu-open"></i></span>
                        </td>
                        <td class="px-5 py-3 text-gray-300">{{report.tss}}
                            <span v-if="i < reports.length -1 && reports[i].tss < reports[i+1].tss "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].tss > reports[i+1].tss "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].tss == reports[i+1].tss "><i class="icon-menu-open"></i></span>
                        </td>
                        <td class="px-5 py-3 text-gray-300">{{report.nh3n}}
                            <span v-if="i < reports.length -1 && reports[i].nh3n < reports[i+1].nh3n "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].nh3n > reports[i+1].nh3n "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].nh3n == reports[i+1].nh3n "><i class="icon-menu-open"></i></span>
                        </td>
                        <td class="px-5 py-3 text-gray-300">{{report.debit}}
                            <span v-if="i < reports.length -1 && reports[i].debit < reports[i+1].debit "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].debit > reports[i+1].debit "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < reports.length -1 && reports[i].debit == reports[i+1].debit "><i class="icon-menu-open"></i></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="page" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>
<script>
import Calendar from '../components/Calendar.vue'
import axios from 'axios'
import Paginate from '../components/Paginate.vue'
import Loading  from 'vue-loading-overlay'
export default {
    components: {
        Calendar,
        Paginate,
        Loading
    },
    data () {
        return {
            isDownload: false,
            isLoading: false,
            filter: '2 menit',
            date: '',
            label: '',
            reports: '',
            page: ''
        }
    },
    methods: {
        onSelect(date, label) {
            this.date = date
            this.label = label
            this.getData()
        },
        onFilter(val) {
            this.filter = val
            this.getData()
        },
        getData (e) {
            this.reports = ''
            this.isLoading = true
            document.documentElement.scrollTop = 0
            axios.get('api/report', {
                params: {
                    page: e,
                    date: this.date,
                    label: this.label,
                    filter: this.filter
                }
            })
            .then((res) => {
                this.isLoading = false
                if(this.filter == '1 jam') {
                    this.reports = []
                    res.data.data.forEach(el => {
                        var debit = parseFloat(el.debit * 60).toFixed(2)
                        this.reports.push({ph: el.ph, tmp: el.tmp, cod: el.cod, tss: el.tss, nh3n: el.nh3n, debit: debit, added_at: el.jam+':00'})
                    });
                    this.page = res.data
                } else {
                    this.reports = res.data.data
                    this.page = res.data
                }
            })
        },
        exportExcel() {
            this.isDownload = true
            axios.get('/api/report/export',{
                    params: {
                        date: this.date,
                        label: this.label,
                        filter: this.filter
                    },
                    responseType: 'blob'
                })
            .then((response) => {
                const time = new Date().getTime();
                const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download =time+"_laporan.xlsx"
                link.click()
                URL.revokeObjectURL(link.href)
                this.isDownload = false
            })
        }
    }
}
</script>

