<template>
    <div class="p-5 mb-32">
        <div class="bg-gray-800 shadow-sm rounded-sm">
            <div class="p-5 flex items-center justify-between">
                <div class="flex items-center">
                    <div>
                        <calendar ref="calendar" @date="onSelect"></calendar>
                    </div>
                    <div class="ml-3">
                        <button @click="onFilter('semua')" :class="{'bg-green-500 text-white border-green-500': filter=='semua'}" class="h-9 bg-gray-700 border border-r-0 border-gray-600 rounded-l-sm focus:outline-none px-5 text-gray-200 hover:bg-green-500 hover:border-green-500">Semua</button>
                        <button @click="onFilter('min')" :class="{'bg-green-500 text-white border-green-500': filter=='min'}"  class="h-9 bg-gray-700 border border-gray-600 focus:outline-none px-5 text-gray-200 hover:bg-green-500 hover:border-green-500">min</button>
                        <button @click="onFilter('max')" :class="{'bg-green-500 text-white border-green-500': filter=='max'}"  class="h-9 bg-gray-700 border border-gray-600 border-l-0 rounded-r-sm focus:outline-none px-5 text-gray-200 hover:bg-green-500 hover:border-green-500">Max</button>
                    </div>
                    <div class="ml-3">
                        <dropdown>
                            <button type="button" class="bg-gray-700 border border-gray-600 relative rounded-sm h-9 focus:outline-none w-40">
                                <div class="flex items-center h-full relative">
                                    <div class="bg-gray-800 bg-opacity-50 text-gray-300 h-full w-9 rounded-l-sm flex justify-center items-center">
                                        <i class="icon-filter3"></i>
                                    </div>
                                    <div class="px-3 text-gray-300">
                                        Filter Parameter
                                    </div>
                                </div>
                                <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-gray-400"></div>
                            </button>
                            <template #dropdown>
                                <div class="w-40 text-gray-300">
                                    <ul>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('pH')" href="#" :class="{'bg-green-500 text-white':parameters.ph}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>pH</div>
                                                    <div v-show="parameters.ph"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('TMP')" href="#" :class="{'bg-green-500 text-white':parameters.tmp}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Temperatur</div>
                                                    <div v-show="parameters.tmp"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('COD')" href="#" :class="{'bg-green-500 text-white':parameters.cod}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>COD</div>
                                                    <div v-show="parameters.cod"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('TSS')" href="#" :class="{'bg-green-500 text-white':parameters.tss}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>TSS</div>
                                                    <div v-show="parameters.tss"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('NH3N')"  href="#" :class="{'bg-green-500 text-white':parameters.nh3n}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>NH3N</div>
                                                    <div v-show="parameters.nh3n"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('DEBIT')" href="#" :class="{'bg-green-500 text-white':parameters.debit}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>DEBIT</div>
                                                    <div v-show="parameters.debit"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </dropdown>
                    </div>
                </div>
                <div>
                    <button class="h-9 px-5 bg-green-500 rounded-sm text-white focus:outline-none hover:bg-green-600">DOWNLOAD</button>
                </div>
            </div>
            <div class="px-5 flex items-center space-x-3">
                <div v-show="parameters.ph" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-green-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>pH</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('pH')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.tmp" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-red-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>TMP</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('TMP')"  href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.cod" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-yellow-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>COD</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('COD')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.tss" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-purple-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>TSS</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('TSS')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.nh3n" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-blue-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>NH3N</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('NH3N')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.debit" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-cyan-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>DEBIT</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('DEBIT')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
            <table class="w-full">
                <thead>
                    <tr>
                        <th @click="sortBy('added_at')" :class="{'text-green-500': sortKey == 'added_at'}" class="px-5 py-3 bg-gray-700 text-gray-300 hover:text-green-500  cursor-pointer">
                            WAKTU
                            <i v-if="sortKey == 'added_at'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('description')" :class="{'text-green-500': sortKey == 'description'}" class="px-5 py-3 bg-gray-700 text-gray-300 hover:text-green-500  cursor-pointer">
                            KETERANGAN
                            <i v-if="sortKey == 'description'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('parameter')" :class="{'text-green-500': sortKey == 'parameter'}" class="px-5 py-3 bg-gray-700 text-gray-300 hover:text-green-500  cursor-pointer">
                            PARAMETER
                            <i v-if="sortKey == 'parameter'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('value')" :class="{'text-green-500': sortKey == 'value'}"  class="px-5 py-3 bg-gray-700 text-gray-300 hover:text-green-500  cursor-pointer text-right">
                            NILAI
                            <i v-if="sortKey == 'value'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="isLoading"  class="divide-y divide-gray-700">
                    <tr v-for="i in 20" :key="i">
                        <td class="px-5 py-4"><div class="w-32 h-3 bg-gray-700 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-64 h-3 bg-gray-700 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-10 h-3 bg-gray-700 animate-pulse"></div></td>
                        <td class="px-5 py-4"><div class="w-12 h-3 bg-gray-700 animate-pulse"></div></td>
                    </tr>
                </tbody>
                <tbody v-if="warnings" class="divide-y divide-gray-700">
                    <tr v-for="(warning, i) in warnings" :key="i">
                        <td class="px-5 py-3 text-gray-300" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.added_at}}</td>
                        <td class="px-5 py-3 text-gray-300" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.description}}</td>
                        <td class="px-5 py-3 text-gray-300" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.parameter}}</td>
                        <td class="px-5 py-3 text-gray-300 text-right" :class="{'text-red-500': warning.threshold == 'max'}">{{warning.value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="page" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>
<script>
import Calendar from '../components/Calendar.vue'
import Dropdown from '../components/Dropdown.vue'
import Paginate from '../components/Paginate.vue'
import axios from 'axios'
export default {
    components: {
        Calendar,
        Paginate,
        Dropdown
    },
    data () {
        return {
            isLoading: true,
            filter: 'semua',
            parameters: {
                ph: '',
                tmp: '',
                cod: '',
                tss: '',
                nh3n: '',
                debit: ''
            },
            warnings: '',
            page: '',
            date: '',
            label: '',
            sortKey: 'added_at',
            sortOrder: 'desc',
            currentPage: ''
        }
    },
    
    methods: {
        onSelect (date, label) {
            this.date = date
            this.label = label
            this.getData()
        },
        onFilter (val) {
            this.filter = val
            this.getData()
        },
        onParameter (val) {
            switch(val) {
                case "pH":
                    this.parameters.ph = val
                    break
                case "TMP":
                    this.parameters.tmp = val
                    break
                case "COD":
                    this.parameters.cod = val
                    break
                case "TSS":
                    this.parameters.tss = val
                    break
                case "NH3N":
                    this.parameters.nh3n = val
                    break
                case "DEBIT":
                    this.parameters.debit = val
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
        onDeletParameter (val) {
            switch(val) {
                case "pH":
                    this.parameters.ph = ''
                    break
                case "TMP":
                    this.parameters.tmp = ''
                    break
                case "COD":
                    this.parameters.cod = ''
                    break
                case "TSS":
                    this.parameters.tss = ''
                    break
                case "NH3N":
                    this.parameters.nh3n = ''
                    break
                case "DEBIT":
                    this.parameters.debit = ''
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
        sortBy (key) {
            if(key == this.sortKey) {
                this.sortOrder = (this.sortOrder == 'desc') ? 'asc': 'desc'
            } else {
                this.sortKey = key
                this.sortOrder = 'desc'
            }
            this.$nextTick(() => {
                this.getData(this.currentPage)
            })
        },
        getData (e) {
            document.documentElement.scrollTop = 0
            this.warnings = ''
            this.isLoading = true
            axios.get('api/warning', {
                params: {
                    page: e,
                    date: this.date,
                    label: this.label,
                    filter: this.filter,
                    parameters: this.parameters,
                    sortkey: this.sortKey,
                    sortorder: this.sortOrder
                }
            })
            .then((res)=> {
                this.currentPage = res.data.current_page
                this.isLoading = false
                this.page = res.data
                this.warnings = res.data.data
            })
        }
    }
    
}
</script>

