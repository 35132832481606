import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Dashboard from '../views/Dashboard.vue'
import Charts from '../views/Charts.vue'
import Early from '../views/Early.vue'
import Report from '../views/Report.vue'
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  { path: '/charts', name: 'Charts', component: Charts, meta: { auth: true}},
  { path: '/early', name: 'Early', component: Early, meta: { auth: true}},
  { path: '/report', name: 'Report', component: Report, meta: { auth: true}},
  { path: '/account', name: 'Akun', component: Account, meta: { auth: true}},
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.auth && !store.getters['auth/authenticated']) next({name:'Login'})
  else next()
  if(!to.meta.auth && store.getters['auth/authenticated']) next({name: 'Dashboard'})
  else next()
})

export default router
