<template>
	<div class="p-5">
		<div class="grid grid-cols-3 gap-5 mb-5">
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">DEBIT</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.debit).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.debit}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-cyan-500 rounded-sm flex items-center text-cyan-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.debit}}</div>
								<div class="leading-none text-xs text-gray-400">m3/menit</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative pl-2" style="height: 98px">
					<canvas id="debit"></canvas>
				</div>
			</div>
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">pH METER</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.ph).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.ph}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-green-500 rounded-sm flex items-center text-green-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.ph}}</div>
								<div class="leading-none text-xs text-gray-400">pH meter</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height: 98px">
					<canvas id="ph"></canvas>
				</div>
			</div>
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">TEMPERATUR</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.tmp).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.tmp}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-red-500 rounded-sm flex items-center text-red-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.tmp}}</div>
								<div class="leading-none text-xs text-gray-400">Celcius</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height: 98px">
					<canvas id="temperatur"></canvas>
				</div>
			</div>
		</div>

		<div class="grid grid-cols-3 gap-5">
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">COD</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.cod).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.cod}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-yellow-500 rounded-sm flex items-center text-yellow-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.cod}}</div>
								<div class="leading-none text-xs text-gray-400">mg/L</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height: 98px">
					<canvas id="cod"></canvas>
				</div>
			</div>
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">TSS</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.tss).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.tss}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-purple-500 rounded-sm flex items-center text-purple-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.tss}}</div>
								<div class="leading-none text-xs text-gray-400">mg/L</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height: 98px">
					<canvas id="tss"></canvas>
				</div>
			</div>
			<div class="col-span-1 shadow-sm rounded-sm bg-gray-800 h-48">
				<div class="p-5 pb-3">
					<div class="grid grid-cols-2 gap-5">
						<div class="col-span-1">
							<div class="font-semibold text-lg text-gray-300">NH3N</div>
							<div class="flex items-center justify-between text-center">
								<div>
									<div class="text-xs text-gray-400">Rata-rata</div>
									<div class="text-green-500 font-medium">{{parseFloat(avg.nh3n).toFixed(2)}}</div>
								</div>
								<div>
									<div class="text-xs text-gray-400">Puncak</div>
									<div class="text-red-500 font-medium">{{max.tss}}</div>
								</div>
							</div>
						</div>
						<div class="col-span-1 text-right border border-blue-500 rounded-sm flex items-center text-blue-500">
							<div class="text-right w-full mr-3">
								<div class="text-3xl font-semibold leading-none">{{modbus.nh3n}}</div>
								<div class="leading-none text-xs text-gray-400">mg/L</div>
							</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height: 98px">
					<canvas id="nh3n"></canvas>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Chart from 'chart.js/auto'
import { db } from '../db'
import axios from 'axios'
export default {
	data () {
		return {
			modbus: '',
			ph: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			tmp: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			cod: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			tss: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			nh3n: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			debit: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			phTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			tmpTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			codTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			tssTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			nh3nTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			debitTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			avg: {
				ph: 0,
				tmp: 0,
				cod: 0,
				tss: 0,
				nh3n: 0,
				debit: 0
			},
			max: {
				ph: 0,
				tmp: 0,
				cod: 0,
				tss: 0,
				nh3n: 0,
				debit: 0
			}
		}
	},
	firebase: {
		modbus: db.ref('iha/indopacific/modbus')
	},
	mounted () {
		this.getData()
		let app = this
		var ctxDebit = document.getElementById('debit')
		window.myDebit = new Chart(ctxDebit, {
			type: 'line',
			data: {
				labels: app.debitTime,
				datasets: [{
					data: app.debit,
					borderColor: 'rgb(5,182,212)',
					backgroundColor: 'rgb(5,182,212, 0.2)',
					tension: 0.4,
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					}
				},
				scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(239,68,68)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
			}
		})

		var ctxPh = document.getElementById('ph')
		window.myPh = new Chart(ctxPh, {
			type: 'bar',
			data: {
				labels: app.phTime,
				datasets: [{
					data: app.ph,
					backgroundColor: 'rgb(16,185,140, 0.7)',
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		})

		var ctxTmp = document.getElementById('temperatur')
		window.myTmp = new Chart(ctxTmp, {
			type: 'bar',
			data: {
				labels: app.tmpTime,
				datasets: [{
					data: app.tmp,
					backgroundColor: 'rgb(239,68,102, 0.7)',
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		})

		var ctxCod = document.getElementById('cod')
		window.myCod = new Chart(ctxCod, {
			type: 'bar',
			data: {
				labels: app.codTime,
				datasets: [{
					data: app.cod,
					backgroundColor: 'rgb(245,158,10, 0.7)',
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		})

		var ctxTss = document.getElementById('tss')
		window.myTss = new Chart(ctxTss, {
			type: 'bar',
			data: {
				labels: app.tssTime,
				datasets: [{
					data: app.tss,
					backgroundColor: 'rgb(168,85,247, 0.7)',
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		})

		var ctxNh3n = document.getElementById('nh3n')
		window.myNh3n = new Chart(ctxNh3n, {
			type: 'bar',
			data: {
				labels: app.nh3nTime,
				datasets: [{
					data: app.nh3n,
					backgroundColor: 'rgb(58,133,247, 0.7)',
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				}
			}
		})
	},
	methods: {
		getData () {
			axios.get('api/dekstop')
			.then((res) => {
				this.avg = res.data.avg
				this.max = res.data.max
			})
		}
	},
	watch: {
		'modbus.debit': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.debit.length > 17) {
				this.debit.splice(0, 1)
			}
			if(this.debitTime.length > 17) {
				this.debitTime.splice(0,1)
			}
			this.debit.push(val)
			this.debitTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myDebit.update()
		},
		'modbus.ph': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.ph.length > 17) {
				this.ph.splice(0, 1)
			}
			if(this.phTime.length > 17) {
				this.phTime.splice(0,1)
			}
			this.ph.push(val)
			this.phTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myPh.update()
		},
		'modbus.tmp': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.tmp.length > 17) {
				this.tmp.splice(0, 1)
			}
			if(this.tmpTime.length > 17) {
				this.tmpTime.splice(0,1)
			}
			this.tmp.push(val)
			this.tmpTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myTmp.update()
		},
		'modbus.cod': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.cod.length > 17) {
				this.cod.splice(0, 1)
			}
			if(this.codTime.length > 17) {
				this.codTime.splice(0,1)
			}
			this.cod.push(val)
			this.codTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myCod.update()
		},
		'modbus.tss': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.tss.length > 17) {
				this.tss.splice(0, 1)
			}
			if(this.tssTime.length > 17) {
				this.tssTime.splice(0,1)
			}
			this.tss.push(val)
			this.tssTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myTss.update()
		},
		'modbus.nh3n': function(val) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.nh3n.length > 17) {
				this.nh3n.splice(0, 1)
			}
			if(this.nh3nTime.length > 17) {
				this.nh3nTime.splice(0,1)
			}
			this.nh3n.push(val)
			this.nh3nTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myNh3n.update()
		}

	},
}
</script>